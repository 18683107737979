
import { OrderActivity } from '@/common/types/OrderActivity.type';
import { clientModule } from '@/modules/client/client.vuex-module';
import ActivityCard from '@/modules/consent/components/activity-card.component.vue';
import { orderModule } from '@/modules/order/orderModule.vuex-module';
import { apiService } from '@/services/api.service';
import { kioskModule } from '@/store/kiosk.vuex-module';
import { Options, Vue } from 'vue-class-component';

@Options({
	name: 'ActivitySelectionView',
	components: { ActivityCard },
})
export default class ActivitySelectionView extends Vue {
	activities: OrderActivity[] = [];
	isLoading = true;

	created(): void {
		if (this.$route.params.orderKey != '0') this.getOrderActivities();
		else if (this.$route.query.unassigned) this.$router.push({ name: 'WaiverForms', query: { ...this.$route.query } });
	}

	get disableContinue() {
		// if editing -> make sure a new activity is selected
		// if (this.isEditing) return !(Object.keys(kioskModule.activityParticipants).length != kioskModule.session.editActivities.length);

		// make sure at least one activity is selected
		return !Object.values(kioskModule.activityParticipants).some((p) => p.length > 0);
	}

	get isEditing() {
		return kioskModule.isEditing;
	}

	onContinue() {
		if (this.isEditing) {
			this.isLoading = true;
			kioskModule.submitWaiver().then((res) => {
				if (res) this.$router.push({ name: 'Finished', query: { ...this.$route.query } });
			});
		} else this.$router.push({ name: 'WaiverForms', query: { ...this.$route.query } });
	}

	goBack() {
		if (this.isEditing) this.$router.push({ name: 'Completed' });
		else this.$router.push({ name: `${kioskModule.minorParticipants.length ? 'MinorsCheckin' : 'AdultCheckin'}`, query: { ...this.$route.query } });
	}

	async getOrderActivities() {
		this.isLoading = true;

		apiService
			.get('getCheckinOrderActivities', {
				orderKey: kioskModule.session.orderKey,
				clientLocationKey: clientModule.location?.ClientLocationKey ?? 0,
				consentKey: kioskModule.consenter.consentKey,
			})
			.then((res) => {
				if (Array.isArray(res) && res.length) {
					this.$log('orderActivities', res);
					this.activities = res.sort((a, b) => {
						return new Date(`${a.AppointmentDate} ${a.Time}`).valueOf() - new Date(`${b.AppointmentDate} ${b.Time}`).valueOf();
					});

					// if one activity all participant consents are included
					if (this.activities.length == 1 && !this.isEditing) {
						const ppts = [];
						if (kioskModule.consenter.isParticipating) ppts.push(kioskModule.consenter.guid);
						kioskModule.minorParticipants.forEach((m) => ppts.push(m.guid));
						kioskModule.assignActivityParticipants({ activityId: this.activities[0].OrderEntityTimeKey, participantId: ppts }).then(() => {
							if (this.isEditing) this.onContinue();
							else this.$router.replace({ name: 'WaiverForms', query: { ...this.$route.query } });
						});
					} else {
						kioskModule.updateLastPage('ActivitySelection');
					}
				}
			})
			.catch(() => {
				throw new Error('Invalid order activities response.');
			})
			.finally(() => (this.isLoading = false));
	}
}
